@import '../../styles/variables';
@import '../../styles/mixin';

.counter__weight_variant_title_weights {
  margin: 0 0 8px;
  min-width: 206px;
  font-weight: 700;
  font-size: $text-base !important;
  line-height: 24px;
  color: $gray-600 !important;

  @media screen and (max-width: 640px) {
    order: 3;
    margin: 12px 0 8px;
  }

  @include media(sm){
    font-size: $text-xl !important;
    margin: 0 0 12px;
  }

  @include media(xl){
    margin: 0 0 20px;
  }
}

.counter__weight_variant_container {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 8px;
  margin-bottom: 12px;

  @media screen and (max-width: 640px) {
    order: 3;
    margin-bottom: 20px;
  }

  @include media(xl){
    margin-bottom: 20px;
  }

 /*  @media screen and (max-width: 420px) {
    min-width: 270px;
  }

  @media screen and (min-width: 500px) {
    min-width: 410px;
  }

  @media screen and (min-width: 590px) {
    min-width: 500px;
  }

  @include media(sm) {
    min-width: 540px;
  }

  @include media(md) {
    min-width: 280px;
  }

  @include media(lg) {
    min-width: 360px;
  }

  @include media(xl) {
    min-width: 400px;
  }

  @include media(3xl) {
    min-width: 507px;
  } */

  .counter__weight_variant_btn {
    //height: 36px;
    padding: 10px 24px;
    border: 1px $detail-weight-variant-btn-border-type
      $detail-weight-variant-btn-border;
    color: $detail-weight-variant-btn-text-color;
    background-color: $detail-weight-variant-btn-bg-color;
    position: relative;
    overflow: visible;

    &:hover {
      border: 1px $detail-weight-variant-btn-border-type
        $detail-weight-variant-btn-hover-border;
      color: $detail-weight-variant-btn-hover-text-color;
      background-color: $detail-weight-variant-btn-hover-bg-color;
    }

    &_active {
      border: 1px $detail-weight-variant-btn-border-type
        $detail-weight-variant-btn-active-border;
      color: $detail-weight-variant-btn-active-text-color;
      background-color: $detail-weight-variant-btn-active-bg-color;
      opacity: 1 !important;
    }

    @include media(lg) {
      padding: 8px 12px;
    }
  }

  .counter__btn-responsibe {
    flex-grow: 1;
    min-width: 150px;
  }

  .weight_variant_btn {
    padding: 8px 12px;
    border-radius: $detail-weight-variant-btn-border-radius;
    min-width: 85px;
    border: 1px solid transparent;
    color: $detail-weight-variant-btn-text-color;
    background-color: $detail-weight-variant-btn-bg-color;
    height: auto;

    &:hover {
      border: 1px $detail-weight-variant-btn-border-type
      $detail-weight-variant-btn-hover-border;
      color: $detail-weight-variant-btn-hover-text-color;
      background-color: $detail-weight-variant-btn-hover-bg-color;

      .weight_variant_btn_content {
        .weight_variant_btn_price {
          color: $detail-weight-variant-btn-hover-info-color;
        }
      }
    }

    &_active {
      border: 1px $detail-weight-variant-btn-border-type
        $detail-weight-variant-btn-active-border;
      color: $detail-weight-variant-btn-active-text-color;
      background-color: $detail-weight-variant-btn-active-bg-color;
      opacity: 1 !important;
    }

    .weight_variant_btn_content {
      display: flex;
      flex-direction: column;

      .weight_variant_btn_label {
        color: $detail-weight-variant-btn-text-color;
        font-size: $text-xs;
        line-height: 1rem;
        font-weight: 500;
      }

      .weight_variant_btn_price_container {
        display: flex;
        gap: 4px;
        justify-content: center;
        align-items: center;

        .weight_variant_btn_price {
          color: $detail-weight-variant-btn-info-color;
          font-size: $text-base;
          line-height: 1.5rem;
          font-weight: 700;

          &_active {
            color: $detail-weight-variant-btn-active-info-color;
          }
        }

        .weight_variant_btn_discount {
          color: $detail-weight-variant-btn-text-color;
          font-size: $text-xs;
          line-height: 1px;
          font-weight: 500;
          text-decoration: line-through;
          height: 100%;
        }
      }
    }
  }
}
