@import '../../styles/variables';
@import '../../styles/mixin';

.links {
  &_with_spacing {
    padding: 16px 0;
  }

  &_container {
    &--full-width {
      max-width: none;
      padding-left: 0;
      padding-right: 0;
    }

    &_with_spacing {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  .carousel_links {
    position: relative;

    &__link {
      display: flex;
      align-items: center;
      column-gap: 10px;
      font-weight: 400;
      height: auto;
      min-height: 38px;
      font-size: $text-base;
      padding: 8px 16px;

      @include media(sm) {
        padding: 12px 16px;
      }

      &--small {
        padding: 8px 16px;
      }

      &--medium {
        @include media(sm) {
          padding: 10px 18px;
        }
      }

      &--large {
        padding: 9px 18px;

        @include media(sm) {
          padding: 10px 18px;
        }
      }

      &_simple {
        height: 52px;
        padding: 16px 0 !important;
      }

      span {
        line-height: 1;
      }

      &__image {
        align-self: center;
        display: flex;
        position: relative;

        &--small {
          height: 22px;
          width: 22px;
        }

        &--medium {
          height: 24px;
          width: 24px;
        }

        &--large {
          height: 26px;
          width: 26px;
        }

        > img {
          height: auto;
          width: auto;
        }
      }
    }
  }
}
